import Main from './components/Main/Func';

function App() {
  return (
    <div>
      <Main/>
    </div>
  );
}

export default App;
